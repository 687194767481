import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const $html = document.querySelector('html');
const locale = $html ? $html.getAttribute('lang') : 'en';
const i18n = new VueI18n({
    locale: locale || 'en',
    fallbackLocale: locale || 'en'
});

export default i18n;
