import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import ja from 'vuetify/src/locale/ja';

Vue.use(Vuetify);
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

export default new Vuetify({
    lang: {
        locales: { ja },
        current: 'ja',
    },
    theme: {
        themes: {
            light: {
                primary: '#0b3363',
            },
        },
    },
});
