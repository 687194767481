import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { AuthStatus } from './modules/auth';

Vue.use(Vuex);

interface Status {
    auth: AuthStatus;
}

const vuexLocal = new VuexPersistence<Status>({
    key: 'auth', // The key to store the state on in the storage provider.
    storage: window.sessionStorage, // or window.sessionStorage or localForage
    // Function that passes the state and returns the state with only the objects you want to store.
    // Function that passes a mutation and lets you decide if it should update the state in localStorage.
    // filter: mutation => (true)
});

export default new Vuex.Store({
    mutations: {},
    actions: {},
    modules: {},
    plugins: [vuexLocal.plugin]
});
