





































import { Component, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios';
import { LaravelSuccess, LaravelError } from '@/types/response';
import AuthModule from '@/login/store/modules/auth';

interface SuccessObject {
    redirect: string;
}
interface ErrorObject {
    email?: string[];
    password?: string[];
}

@Component
export default class App extends Vue {
    // 入力データ
    private email = AuthModule.email;
    private password = '';
    private remember = AuthModule.remember;

    // パスワード入力蘭の表示状態
    private showPassword = false;

    // autocomplete によって自動入力が行われたとき
    private isAutocompleted = false;

    // バリデーションによって戻ってきたエラー
    private errors = {
        email: '',
        password: '',
    };

    // 送信中
    private progress = false;

    @Watch('email')
    onChangeEmailValue() {
        this.errors.email = '';
        AuthModule.SET_EMAIL(this.email);
        this.isAutocompleted = false;
    }

    @Watch('password')
    onChangePasswordValue() {
        this.errors.password = '';
        this.isAutocompleted = false;
    }

    @Watch('remember')
    onChangeRememberValue() {
        AuthModule.SET_REMEMBER(this.remember);
    }

    mounted() {
        // autocomplete によって自動入力が行われているかどうかを検知
        let times = 0;
        const interval = setInterval(() => {
            const dirtyPwd = document.querySelector('input[type="password"]:-webkit-autofill');
            times += 1;
            if (dirtyPwd || times === 20) {
                clearInterval(interval);
                this.isAutocompleted = dirtyPwd !== null;
            }
        }, 100);
    }

    /**
     * ログイン
     */
    private submit() {
        this.progress = true;
        this.errors = {
            email: '',
            password: '',
        };
        const formData = new FormData();
        formData.append('email', this.email);
        formData.append('password', this.password);
        if (this.remember) {
            formData.append('remember', '1');
        }
        axios
            .post(Vue.prototype.$config.post, formData)
            .then((response) => {
                // ログイン成功時はリダイレクト
                const data: SuccessObject = response.data;
                document.location.href = data.redirect;
            })
            .catch((error) => {
                const data: LaravelError = error.response.data;
                if (data.errors) {
                    const errors = data.errors as ErrorObject;
                    if (errors.email && errors.email.length > 0) {
                        this.errors.email = errors.email[0];
                    }
                    if (errors.password && errors.password.length > 0) {
                        this.errors.password = errors.password[0];
                    }
                }
                this.progress = false;
            });
    }
}
