import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import store from './store';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';

const $el = document.getElementById('app');
if ($el !== null) {
    // グローバル変数を登録
    if (Vue.prototype.$config === undefined) {
        Vue.prototype.$config = {};
    }
    Vue.prototype.$config = Object.assign(
        {
            post: $el.getAttribute('data-login'),
            passwordRequest: $el.getAttribute('data-passwordRequest')
        },
        Vue.prototype.$config
    );
}

const $csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
if ($csrfTokenMeta !== null) {
    // デフォルトでヘッダーにトークンを含める
    axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': $csrfTokenMeta.getAttribute('content'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };
}

i18n.setLocaleMessage('en', require('./locales/en.json'));
i18n.setLocaleMessage('ja', require('./locales/ja.json'));

new Vue({
    store: store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount('#app');
