import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/login/store';

export interface AuthStatus {
    email: string;
    remember: number | null;
}

@Module({ dynamic: true, store, name: 'auth', namespaced: true, preserveState: sessionStorage.getItem('auth') !== null })
class AuthModule extends VuexModule implements AuthStatus {
    // メールアドレス
    public email = '';

    // ログイン状態の保存
    public remember: number | null = null;

    /**
     * メールアドレス設定
     *
     * @param {string} value
     * @memberof AuthModule
     */
    @Mutation
    public SET_EMAIL(value: string) {
        this.email = value;
    }

    /**
     * ログイン状態の保存設定
     *
     * @param {number | null} value
     * @memberof AuthModule
     */
    @Mutation
    public SET_REMEMBER(value: number | null) {
        this.remember = value;
    }
}

export default getModule(AuthModule, store);
